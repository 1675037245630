import React from 'react';
import { DrawingToolProps, Point, useDrawTool } from '../services/DrawTool';
import CustomBrushCursor from './CustomBrushCursor';

interface BrushCatcherProps {
    toolUsed: DrawingToolProps;
    snapToGrid: boolean;
    gridSpacing: number;
    lineWidth: number;
    erase: boolean;
    children?: React.ReactNode;
}

const BrushCatcher: React.FC<BrushCatcherProps> = ({ children, toolUsed, snapToGrid, gridSpacing, lineWidth, erase }) => {

    const [isOnDrawingArea, setIsOnDrawingArea] = React.useState(false);
    const [cursorPosition, setCursorPosition] = React.useState<Point>({ x: 0, y: 0 });
    
    const { startDraw, draw, endDraw, isDrawing } = useDrawTool({...toolUsed, snapToGrid, gridSpacing});

    function onDraw(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) {
        const { clientX, clientY } = 'touches' in e ? e.touches[0] : e;
        setCursorPosition({ x: clientX, y: clientY });
        if (!isDrawing) return;
        draw(e);
    }

    return (
        <div 
          className="canvas-container"
          onTouchStart={startDraw}
          onTouchMove={onDraw}
          onTouchEnd={endDraw}
          onMouseEnter={() => setIsOnDrawingArea(true)}
          onMouseLeave={() => setIsOnDrawingArea(false)}
          onMouseDown={startDraw}
          onMouseMove={onDraw}
          onMouseUp={endDraw}
        >
          {children}
          <CustomBrushCursor
            visible={isOnDrawingArea}
            mouseX={cursorPosition?.x}
            mouseY={cursorPosition?.y}
            selectedWidth={lineWidth}
            eraser={erase}
          />
        </div>
    )
}

export default BrushCatcher;