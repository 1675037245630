/* tslint:disable */
/* eslint-disable */
let wasm;

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

let WASM_VECTOR_LEN = 0;

function passArray8ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 1, 1) >>> 0;
    getUint8ArrayMemory0().set(arg, ptr / 1);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}

function getArrayU8FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint8ArrayMemory0().subarray(ptr / 1, ptr / 1 + len);
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
}

function takeFromExternrefTable0(idx) {
    const value = wasm.__wbindgen_export_0.get(idx);
    wasm.__externref_table_dealloc(idx);
    return value;
}
 
let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getDataViewMemory0();
    const result = [];
    for (let i = ptr; i < ptr + 4 * len; i += 4) {
        result.push(wasm.__wbindgen_export_0.get(mem.getUint32(i, true)));
    }
    wasm.__externref_drop_slice(ptr, len);
    return result;
}

const PixelBoxFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_pixelbox_free(ptr >>> 0, 1));

export class PixelBox {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(PixelBox.prototype);
        obj.__wbg_ptr = ptr;
        PixelBoxFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        PixelBoxFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_pixelbox_free(ptr, 0);
    }
    /**
     * @param {number} width
     * @param {number} height
     */
    constructor(width, height) {
        const ret = wasm.pixelbox_new(width, height);
        this.__wbg_ptr = ret >>> 0;
        PixelBoxFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * @returns {number}
     */
    get width() {
        const ret = wasm.pixelbox_width(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @returns {number}
     */
    get height() {
        const ret = wasm.pixelbox_height(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @returns {number}
     */
    get pixelcount() {
        const ret = wasm.pixelbox_pixelcount(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @param {Uint8Array} rgba
     */
    fromRGBA(rgba) {
        const ptr0 = passArray8ToWasm0(rgba, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.pixelbox_fromRGBA(this.__wbg_ptr, ptr0, len0);
    }
    /**
     * @param {number} start_x
     * @param {number} start_y
     * @param {number} width
     * @param {number} height
     * @returns {Uint8Array}
     */
    toRGBA(start_x, start_y, width, height) {
        const ret = wasm.pixelbox_toRGBA(this.__wbg_ptr, start_x, start_y, width, height);
        var v1 = getArrayU8FromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        return v1;
    }
    /**
     * @param {number} x
     * @param {number} y
     * @param {number} color
     */
    setPixel(x, y, color) {
        wasm.pixelbox_setPixel(this.__wbg_ptr, x, y, color);
    }
    /**
     * @param {Uint8Array} shader
     * @returns {boolean}
     */
    setShader(shader) {
        const ptr0 = passArray8ToWasm0(shader, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.pixelbox_setShader(this.__wbg_ptr, ptr0, len0);
        return ret !== 0;
    }
    /**
     * @param {number} x
     * @param {number} y
     * @returns {number}
     */
    getPixel(x, y) {
        const ret = wasm.pixelbox_getPixel(this.__wbg_ptr, x, y);
        return ret;
    }
    clear() {
        wasm.pixelbox_clear(this.__wbg_ptr);
    }
    /**
     * @returns {Uint8Array}
     */
    get buffer() {
        const ret = wasm.pixelbox_buffer(this.__wbg_ptr);
        var v1 = getArrayU8FromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        return v1;
    }
    /**
     * @param {number} x
     * @param {number} y
     * @param {number} radius
     * @param {number} color
     */
    drawDot(x, y, radius, color) {
        wasm.pixelbox_drawDot(this.__wbg_ptr, x, y, radius, color);
    }
    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @param {number} radius
     * @param {number} color
     */
    drawLine(x1, y1, x2, y2, radius, color) {
        wasm.pixelbox_drawLine(this.__wbg_ptr, x1, y1, x2, y2, radius, color);
    }
    /**
     * @param {Uint8Array} bytes
     * @returns {PixelBox}
     */
    static fromBuffer(bytes) {
        const ptr0 = passArray8ToWasm0(bytes, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.pixelbox_fromBuffer(ptr0, len0);
        return PixelBox.__wrap(ret);
    }
    /**
     * @param {PixelBox} pb
     * @param {number} x
     * @param {number} y
     */
    apply(pb, x, y) {
        _assertClass(pb, PixelBox);
        wasm.pixelbox_apply(this.__wbg_ptr, pb.__wbg_ptr, x, y);
    }
}

const ProtocolMessageFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_protocolmessage_free(ptr >>> 0, 1));

export class ProtocolMessage {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ProtocolMessage.prototype);
        obj.__wbg_ptr = ptr;
        ProtocolMessageFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ProtocolMessageFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_protocolmessage_free(ptr, 0);
    }
    /**
     * @returns {number}
     */
    getType() {
        const ret = wasm.protocolmessage_getType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} payloadtype
     */
    setType(payloadtype) {
        wasm.protocolmessage_setType(this.__wbg_ptr, payloadtype);
    }
    /**
     * @param {number} instruction
     * @param {number} payload_type
     */
    constructor(instruction, payload_type) {
        const ret = wasm.protocolmessage_new(instruction, payload_type);
        this.__wbg_ptr = ret >>> 0;
        ProtocolMessageFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * @param {Uint8Array} payload
     */
    addBuffer(payload) {
        const ptr0 = passArray8ToWasm0(payload, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.protocolmessage_addBuffer(this.__wbg_ptr, ptr0, len0);
    }
    /**
     * @returns {Uint8Array}
     */
    get buffer() {
        const ret = wasm.protocolmessage_buffer(this.__wbg_ptr);
        var v1 = getArrayU8FromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        return v1;
    }
    /**
     * @param {Uint8Array} bytes
     * @returns {ProtocolMessage}
     */
    static fromBuffer(bytes) {
        const ptr0 = passArray8ToWasm0(bytes, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.protocolmessage_fromBuffer(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return ProtocolMessage.__wrap(ret[0]);
    }
    /**
     * @returns {(Vector)[]}
     */
    get vectors() {
        const ret = wasm.protocolmessage_vectors(this.__wbg_ptr);
        if (ret[3]) {
            throw takeFromExternrefTable0(ret[2]);
        }
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {(PixelBox)[]}
     */
    get pixelbox() {
        const ret = wasm.protocolmessage_pixelbox(this.__wbg_ptr);
        if (ret[3]) {
            throw takeFromExternrefTable0(ret[2]);
        }
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
}

const RGBFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rgb_free(ptr >>> 0, 1));

export class RGB {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RGB.prototype);
        obj.__wbg_ptr = ptr;
        RGBFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RGBFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rgb_free(ptr, 0);
    }
}

const RGBAFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rgba_free(ptr >>> 0, 1));

export class RGBA {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RGBAFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rgba_free(ptr, 0);
    }
    /**
     * @returns {number}
     */
    get r() {
        const ret = wasm.__wbg_get_rgba_r(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set r(arg0) {
        wasm.__wbg_set_rgba_r(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get g() {
        const ret = wasm.__wbg_get_rgba_g(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set g(arg0) {
        wasm.__wbg_set_rgba_g(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get b() {
        const ret = wasm.__wbg_get_rgba_b(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set b(arg0) {
        wasm.__wbg_set_rgba_b(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {number}
     */
    get a() {
        const ret = wasm.__wbg_get_rgba_a(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} arg0
     */
    set a(arg0) {
        wasm.__wbg_set_rgba_a(this.__wbg_ptr, arg0);
    }
    /**
     * @param {number} r
     * @param {number} g
     * @param {number} b
     * @param {number} a
     */
    constructor(r, g, b, a) {
        const ret = wasm.rgba_new(r, g, b, a);
        this.__wbg_ptr = ret >>> 0;
        RGBAFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * @returns {number}
     */
    a() {
        const ret = wasm.rgba_a(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    r() {
        const ret = wasm.rgba_r(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    g() {
        const ret = wasm.rgba_g(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    b() {
        const ret = wasm.rgba_b(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {RGB}
     */
    to_rgb() {
        const ret = wasm.rgba_to_rgb(this.__wbg_ptr);
        return RGB.__wrap(ret);
    }
}

const VectorFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_vector_free(ptr >>> 0, 1));

export class Vector {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Vector.prototype);
        obj.__wbg_ptr = ptr;
        VectorFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        VectorFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_vector_free(ptr, 0);
    }
    /**
     * @param {number} layer
     * @param {number} tool
     * @param {number} color
     * @param {number} modifier
     * @param {number} start_x
     * @param {number} start_y
     * @param {number} end_x
     * @param {number} end_y
     */
    constructor(layer, tool, color, modifier, start_x, start_y, end_x, end_y) {
        const ret = wasm.vector_new(layer, tool, color, modifier, start_x, start_y, end_x, end_y);
        this.__wbg_ptr = ret >>> 0;
        VectorFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * @returns {number}
     */
    get layer() {
        const ret = wasm.vector_layer(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get tool() {
        const ret = wasm.vector_tool(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get color() {
        const ret = wasm.vector_color(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get modifier() {
        const ret = wasm.vector_modifier(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get start_x() {
        const ret = wasm.vector_start_x(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get start_y() {
        const ret = wasm.vector_start_y(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get end_x() {
        const ret = wasm.vector_end_x(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {number}
     */
    get end_y() {
        const ret = wasm.vector_end_y(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Uint8Array}
     */
    get buffer() {
        const ret = wasm.vector_buffer(this.__wbg_ptr);
        var v1 = getArrayU8FromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        return v1;
    }
    /**
     * @param {Uint8Array} bytes
     * @returns {Vector}
     */
    static fromBuffer(bytes) {
        const ptr0 = passArray8ToWasm0(bytes, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.vector_fromBuffer(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Vector.__wrap(ret[0]);
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_pixelbox_new = function(arg0) {
        const ret = PixelBox.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_vector_new = function(arg0) {
        const ret = Vector.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbindgen_init_externref_table = function() {
        const table = wasm.__wbindgen_export_0;
        const offset = table.grow(4);
        table.set(0, undefined);
        table.set(offset + 0, undefined);
        table.set(offset + 1, null);
        table.set(offset + 2, true);
        table.set(offset + 3, false);
        ;
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };

    return imports;
}

function __wbg_init_memory(imports, memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedUint8ArrayMemory0 = null;


    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;


    if (typeof module !== 'undefined') {
        if (Object.getPrototypeOf(module) === Object.prototype) {
            ({module} = module)
        } else {
            console.warn('using deprecated parameters for `initSync()`; pass a single object instead')
        }
    }

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;


    if (typeof module_or_path !== 'undefined') {
        if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
            ({module_or_path} = module_or_path)
        } else {
            console.warn('using deprecated parameters for the initialization function; pass a single object instead')
        }
    }

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('fwb_bg.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof module_or_path === 'string' || (typeof Request === 'function' && module_or_path instanceof Request) || (typeof URL === 'function' && module_or_path instanceof URL)) {
        module_or_path = fetch(module_or_path);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await module_or_path, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
