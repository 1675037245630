import { useEffect, useMemo, useRef, useState } from "react";
import { Point } from "./DrawTool";
import SocketService from "./socket";
import { useScreenResize } from "../context/ScreenSizeContext";

export interface PostItNoteData {
    uuid?: string;
    location: Point;
    contents?: PostItNoteContent[];
}

interface PostItNoteLibrary {
    [id: string]: {
        location: Point;
        contents: PostItNoteContent[];
    };
}

export interface PostItNoteContent {
    color?: string;
    text?: string;
    pixelBox?: Uint8Array;
    position: Point;
}

let startingPoint: Point;

export function usePostItNoteService() {

    const [ postItNotes, setPostItNotes ] = useState<PostItNoteLibrary>({} as PostItNoteLibrary);

    const resetPostItNotes = () => {
        //console.log("Resetting post-it notes");
        setPostItNotes({});
    }

    const deletePostItNote = (uuid: string, send = true) => {
        console.log("Deleting post-it note", uuid, send);
        
        setPostItNotes(postItNotes => {
            const newPostItNotes = { ...postItNotes };
            delete newPostItNotes[uuid];
            return newPostItNotes;
        });
        
        send && SocketService.deletePostIt(uuid);
    }

    const updatePostItNote = (data: PostItNoteData, send = true) => {
        //console.log("Updating post-it note", data, send);
        if (data.uuid) {
            const postItNote = postItNotes[data.uuid] || {};
            postItNote.location = { x: data.location.x, y: data.location.y };
            postItNote.contents = data.contents || [];
            setPostItNotes(postItNotes => ({
                ...postItNotes,
                [data.uuid!]: postItNote
            }));
            if (send) {
                SocketService.updatePostIt(data);
            }
        }
    }
    
    useEffect(() => {
        //console.log('usePostItNoteService');
        SocketService.connect();
        SocketService.onPostIt(updatePostItNote);
        SocketService.onPostItDelete(deletePostItNote);
    }, []);

    useEffect(() => {
        //console.log('usePostItNoteService', postItNotes);
    }, [postItNotes]);

    const upToDateNotes = useMemo(() => {
        //console.log('reforming postItNotes');
        return { ...postItNotes };
    }, [postItNotes]);

    //console.log('postItNotes', postItNotes);
    return { postItNotes: upToDateNotes, updatePostItNote, resetPostItNotes, deletePostItNote };
}

export function usePostItNoteBrush(deSelect: () => void) {

    const screenSize = useScreenResize();

    return {
        onStartDraw: (sp: Point) => startingPoint = sp,
        onDraw: (_: Point, toPoint: Point) => {
            //console.log("Finding a place for a post-it note");
        },
        onEndDraw: (endingPoint: Point) => {
            SocketService.createPostIt({
                location: { x: endingPoint.x + screenSize.offset.x, y: endingPoint.y + screenSize.offset.y },
                contents: []
            });
            deSelect();
        }
    }
}