import React, { useRef, useState } from "react";

export type Point = { x: number; y: number, layer?: number };

export interface DrawingToolProps {
    onStartDraw?: (startDrawPoint: Point) => void;
    onDraw?: (strokeStart: Point, strokeEnd: Point) => void;
    onEndDraw?: (endDrawPoint: Point) => void;
    snapToGrid?: boolean;
    gridSpacing?: number;
}

export const useDrawTool = (props: DrawingToolProps) => {

    const { onStartDraw, onDraw, onEndDraw, snapToGrid, gridSpacing } = props;
    const [isDrawing, setIsDrawing] = useState(false);

    const lastPoint = useRef<Point>({ x: 0, y: 0 });

    const currentPath = React.useRef<Point[]>([]);

    function snap(point: Point, gridSpacing: number): Point {
        return {
            x: Math.round(point.x / gridSpacing) * gridSpacing,
            y: Math.round(point.y / gridSpacing) * gridSpacing,
        };
    }
    
    const startDraw = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
        const { x, y } = snapToGrid ? snap({ x: clientX, y: clientY }, gridSpacing || 10) : { x: clientX, y: clientY }; 
        onStartDraw && onStartDraw({ x, y });
        setIsDrawing(true);
        lastPoint.current = { x, y };
        currentPath.current = [{ x, y }]; // Start a new path
    };

    const draw = (event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        if (!isDrawing) return;
        const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
        const { x, y } = snapToGrid ? snap({ x: clientX, y: clientY }, gridSpacing || 10) : { x: clientX, y: clientY }; 
        
        onDraw && onDraw({ x: lastPoint.current.x, y: lastPoint.current.y }, { x, y });
        // Update last position
        lastPoint.current = { x, y };
        // Add the current point to the path
        addPoint({ x, y });
    };
    
    const endDraw = () => {
        const { x, y } = snapToGrid ? snap({ x: lastPoint.current.x, y: lastPoint.current.y }, gridSpacing || 10) : { x: lastPoint.current.x, y: lastPoint.current.y }; 
        setIsDrawing(false);
        onEndDraw && onEndDraw({ x, y });
        if (currentPath.current.length > 0) {
          clearPath();
        }
    };

    const stopDraw = () => {
        setIsDrawing(false);
        onEndDraw && onEndDraw({ x: lastPoint.current.x, y: lastPoint.current.y });
        if (currentPath.current.length > 0) {
          clearPath();
        }
    }
    
    const addPoint = (point: Point) => {
        currentPath.current.push(point);
    };
    
    const clearPath = () => {
        currentPath.current = [];
    };
    
    return {
        startDraw,
        draw,
        endDraw,
        stopDraw,
        isDrawing,
        currentPath: currentPath.current,
        lastPoint: lastPoint.current,
        addPoint,
        clearPath,
    };
};