import React, { ReactNode, Ref, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { PostItNoteContent, PostItNoteData, usePostItNoteService } from "../services/PostItNoteService";
import { IonCard, IonCardContent, IonCardSubtitle, IonMenuToggle, IonModal, IonTitle } from "@ionic/react";
import "./PostItNoteOverlay.css";
import { useScreenResize } from "../context/ScreenSizeContext";
import init, { PixelBox } from "../components/fwb-wasm/fwb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

export const pbSize = [200, 200];

export const wasmReady = init();

interface DraggableComponentProps extends PostItNoteData {
    onClick: () => void;
    onPositionUpdate: (position: { x: number, y: number }) => void;
}

interface PostItToolStripProps {
    selectTool: (tool: number) => void;
}

const PostItToolStrip: React.FC<PostItToolStripProps> = ({ selectTool }) => {

    return (
        <div className="post-it-toolstrip">
            <button onClick={() => selectTool(0)} className="post-it-toolstrip-button"><FontAwesomeIcon icon={faPen} /></button>
            <button onClick={() => selectTool(1)} className="post-it-toolstrip-button"><FontAwesomeIcon icon={faEraser} /></button>
            <button onClick={() => selectTool(2)} className="post-it-toolstrip-button"><FontAwesomeIcon icon={faTrashCan} /></button>
        </div>
    );
}

const PostIt: React.FC<DraggableComponentProps> = ({ uuid, location, contents, onPositionUpdate, onClick }) => {

    const screenSize = useScreenResize();
    const [ initialPosition, setInitialPosition ] = useState({ x: location.x, y: location.y });
    const [ clickDistance, setClickDistance ] = useState({ x: 0, y: 0 });
    const [ isDragging, setIsDragging ] = useState(false);
    const [ isAClick, setIsAClick ] = useState(true);
    const [ position, setPosition ] = useState({ x: location.x, y: location.y, visible: true });
    const [ initialized, setInitialized ] = useState(false);
    const pbref = React.useRef<PixelBox>();
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const animRef = React.useRef<number>(0);

    const anim = useCallback(() => {
        if (canvasRef.current && pbref.current && initialized) {
            const ctx = canvasRef.current?.getContext('2d');
            if (!ctx) return;
            const id = new ImageData(200, 200);
            id.data.set(pbref.current.toRGBA(0, 0, pbSize[0], pbSize[1]));
            ctx.putImageData(id, 0, 0);
        }
        animRef.current = requestAnimationFrame(anim);
    }, [canvasRef, pbref]);

    useEffect(() => {
        wasmReady.then(() => {
            //console.log('wasm ready');
            setInitialized(true);
        });
        return () => {
            setInitialized(false);
            cancelAnimationFrame(animRef.current);
        }
    }, []);

    useEffect(() => {
        //console.log('initialized', initialized);
        if (initialized) {
            pbref.current = new PixelBox(pbSize[0], pbSize[1]);
            animRef.current = requestAnimationFrame(anim);
        }
    }, [initialized]);

    useEffect(() => {
        setPosition({ x: location.x, y: location.y, visible: true });
    }, [location]);

    useEffect(() => {
        //console.log('PostItNoteData changed', contents, pbref.current);
        if (pbref.current && contents) {
            contents.forEach((content) => {
                if (content.pixelBox) {
                    if (content.pixelBox instanceof ArrayBuffer) {
                        pbref.current = PixelBox.fromBuffer(new Uint8Array(content.pixelBox));
                    } 
                }
            });
        }
    }, [contents, initialized]);

    useEffect(() => {
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx || !pbref.current) return;
        const id = new ImageData(200, 200);
        try {
            id.data.set(pbref.current.toRGBA(0, 0, pbSize[0], pbSize[1]));
        } catch (e) {
            console.log('error setting image data', pbref.current, pbref.current?.buffer);
            console.error(e);
        }
        ctx.putImageData(id, 0, 0);
    });

    const mouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        const startX = "touches" in e ? e.touches[0].clientX : e.clientX;
        const startY = "touches" in e ? e.touches[0].clientY : e.clientY;
        //console.log('mouseDown');
        setInitialPosition({ x: startX, y: startY });
        setClickDistance({ x: startX - position.x, y: startY - position.y });
        setIsAClick(true);
        setIsDragging(true);
    }

    const mouseEnter = () => {
        if (isDragging) {
            setIsAClick(false);
        }
    }

    const mouseUp = () => {
        if (isAClick) {
            //console.log('is a click');
            setIsAClick(false);
            onClick();
        }
        setIsDragging(false);
        onPositionUpdate(position);
    }

    const mouseleave = () => {
        if (!isDragging) return;
        const finalPosition = { x: initialPosition.x - clickDistance.x, y: initialPosition.y - clickDistance.y, visible: true };
        setPosition(finalPosition);
        setIsAClick(false);
    }

    const mouseMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isDragging) return;
        const moveX = "touches" in e ? e.touches[0].clientX : e.clientX;
        const moveY = "touches" in e ? e.touches[0].clientY : e.clientY;
        setPosition({ x: moveX - clickDistance.x, y: moveY - clickDistance.y, visible: true });
        if (Math.abs(initialPosition.y - moveY) > 5 || Math.abs(initialPosition.x - moveX) > 5) {
            setIsAClick(false);
        }
    }

    return (
        <div
            style={{ left: `${(position.x - screenSize.offset.x) / screenSize.zoomFactor }px`, top: `${(position.y - screenSize.offset.y) / screenSize.zoomFactor}px` }}
            onMouseDown={mouseDown}
            onTouchStart={mouseDown}
            onMouseUp={mouseUp}
            onMouseEnter={mouseEnter}
            onMouseMove={mouseMove}
            onTouchMove={mouseMove}
            onMouseLeave={mouseleave}
            onTouchEnd={mouseUp}
            onTouchCancel={mouseUp}
            className="post-it-note-container"
        >
            <IonCard 
                className="post-it-note"
                color={"dark"}
                
            >
                <IonTitle>{uuid}</IonTitle>
                <IonCardSubtitle className="post-it-subtitle">Sticky Note</IonCardSubtitle>
                <canvas 
                    width={pbSize[0]}
                    height={pbSize[1]}
                    className="post-it-canvas" 
                    ref={canvasRef}>
                </canvas>
            </IonCard>
            
        </div>
    );
}

interface PostItEditorProps {
    onDismiss: () => void;
    onDraw: (pbbytes: PixelBox) => void;
    id?: string | null;
    pixelBox?: Uint8Array;
}

const PenCursor: React.FC<{ x: number, y: number }> = ({ x, y }) => {
    return (
        <span 
            style={{ position: "absolute", left: `${x}px`, top: `${y}px`, transform: "translate(-10%, -100%)" }}
            className="pen-cursor"
        >
            <FontAwesomeIcon size={'xl'} icon={faPen} />
        </span>);
}

interface PostItModalProps {
    isOpen: boolean;
    className?: string;
    onClose: () => void;
    children: React.ReactNode;
}

const PostItModal: React.FC<PostItModalProps> = ({ onClose, className, children, isOpen }) => {

    if (!isOpen) return null; // Don't render if the modal is closed

    return (
        <div className={`${className ? className + ' ' : ''}post-it-modal-backdrop`} onClick={onClose}>
        <div className="post-it-modal" onClick={(e) => e.stopPropagation()}>
            <button className="post-it-modal-close" onClick={onClose}>
            ×
            </button>
            {children}
        </div>
        </div>
    );

}

const PostItEditor: React.FC<PostItEditorProps> = ({ id, onDismiss, pixelBox, onDraw }) => {

    const [ visible, setVisible ] = useState(false);
    const [ color, setColor ] = useState(0b00100001);
    const [ size, setSize ] = useState(1);
    const [ mouseOver, setMouseOver ] = useState(false);
    const [ isDrawing, setIsDrawing ] = useState<boolean>(false);
    
    const [ cursorPosition, setCursorPosition ] = useState({ x: 0, y: 0 });

    const { deletePostItNote } = usePostItNoteService();

    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const animRef = React.useRef<number>(0);
    const pbref = React.useRef<PixelBox>();
    const previousDrawPoint = React.useRef({ x: 0, y: 0 });
    const pbSize = [200, 200];
    const widthMultiplier = 2;
    const heightMultiplier = 2;

    const { os, width, height } = React.useMemo(() => {
        //console.log('calculating os');
        const os = canvasRef.current?.getBoundingClientRect();
        const width = os?.width;
        const height = os?.height;
        return { os, width, height, widthMultiplier, heightMultiplier };
    }, [visible, canvasRef.current]);


    //console.log('os', canvasRef.current, os, width, height, widthMultiplier, heightMultiplier);
    
    const anim = () => {
        if (canvasRef.current && pbref.current && visible) {
            const ctx = canvasRef.current?.getContext('2d');
            if (!ctx) return;
            const id = new ImageData(200, 200);
            id.data.set(pbref.current.toRGBA(0, 0, pbSize[0], pbSize[1]));
            ctx.putImageData(id, 0, 0);
        }
        animRef.current = requestAnimationFrame(anim);
    }

    useEffect(() => {
        wasmReady.then(() => {
            //console.log('changing pixelBox', pixelBox);
            if (pixelBox) {
                pbref.current = PixelBox.fromBuffer(new Uint8Array(pixelBox));
            } else {
                pbref.current = new PixelBox(pbSize[0], pbSize[1]);
            }
        });
    }, [id]);

    useEffect(() => {
        if (visible) {
            animRef.current = requestAnimationFrame(anim);
        } else {
            cancelAnimationFrame(animRef.current);
        }
    }, [visible]);

    const onMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        setIsDrawing(true);
        if (!width || !height) return;
        previousDrawPoint.current = ({ x: "touches" in e ? e.touches[0].clientX - os!.left : e.clientX - os!.left, y: "touches" in e ? e.touches[0].clientY - os!.top : e.clientY - os!.top });
    }

    const onMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        if (!os) return;
        //console.log('mouseMove', widthMultiplier, heightMultiplier);
        const cdp = { x: "touches" in e ? e.touches[0].clientX - os!.left : e.clientX - os!.left, y: "touches" in e ? e.touches[0].clientY - os!.top : e.clientY - os!.top };
        setCursorPosition({ x: cdp.x, y: cdp.y});
        if (!isDrawing || !pbref.current) return;
        pbref.current.drawLine(previousDrawPoint.current.x / widthMultiplier, previousDrawPoint.current.y / heightMultiplier, cdp.x / widthMultiplier, cdp.y / heightMultiplier, size, color);
        previousDrawPoint.current = cdp;
    }

    const onMouseUp = () => {
        if (isDrawing && pbref.current) {
            onDraw(pbref.current);
        }
        setIsDrawing(false);
    }

    const onMouseLeave = () => {
        setMouseOver(false);
        onMouseUp();
    }

    const onMouseEnter = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setMouseOver(true);
    }

    useEffect(() => {
        setVisible(id ? true : false);
        return () => {
            setVisible(false);
        }
    }, [id]);

    return (
        <PostItModal
            className="post-it-editor"
            onClose={onDismiss}
            isOpen={visible}
        >
            <PostItToolStrip selectTool={(tool: number) => {
                if (tool === 0) {
                    const newColor = color === 0b00100001 ? 0b00100010 : 0b00100001;
                    setSize(1);
                    setColor(newColor);
                }
                if (tool === 1) {
                    setColor(0b00000000);
                    setSize(5);
                }
                if (tool === 2) {
                    deletePostItNote(id!);
                    onDismiss();
                }
            }} />
           <div 
                className="post-it-editor-brush-catcher"
                onMouseDown={onMouseDown}
                onTouchStart={onMouseDown}
                onMouseMove={onMouseMove}
                onTouchMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                onTouchEnd={onMouseUp}
                onTouchCancel={onMouseUp}
            >
                
                <canvas 
                    width={pbSize[0]}
                    height={pbSize[1]}
                    className="post-it-editor-canvas"
                    ref={canvasRef}>
                </canvas>
                
                {mouseOver && <PenCursor {...cursorPosition} />}
            </div>
           
            
        </PostItModal>
    );
}

interface PostItNoteOverlayProps {
    postItNotes: { [id: string]: PostItNoteData };
}

export const PostItNoteOverlay: React.FC<PostItNoteOverlayProps> = ({ postItNotes }) => {

    const { updatePostItNote } = usePostItNoteService();
    const [ selectedPostItNote, setSelectedPostItNote ] = useState<string>("");

    const onSelectPostItNote = (id: string) => {
        setSelectedPostItNote(id);
    }

    const onDraw = (pbbytes: PixelBox) => {
        //console.log(pbbytes);
        updatePostItNote({ 
            uuid: selectedPostItNote!,
            location: postItNotes[selectedPostItNote!].location,   
            contents: [{ pixelBox: pbbytes.buffer, position: { x: 0, y: 0 } }] 
        });
    }

    //console.log('postItNotes', postItNotes);
    const notes = useMemo(() => {
        console.log('updating notes');
        return Object.keys(postItNotes).map((uuid) => {
            //console.log('uuid', uuid);
            const postItNote = postItNotes[uuid];
            return (<PostIt 
                key={uuid} 
                {...postItNote} 
                onPositionUpdate={(p) => {
                    updatePostItNote({ uuid, location: { x: p.x, y: p.y }, contents: postItNote.contents });
                }}
                onClick={() => onSelectPostItNote(uuid)}
            />);
        });
    }, [postItNotes]);

    const selectedContents = postItNotes[selectedPostItNote]?.contents ? postItNotes[selectedPostItNote].contents as PostItNoteContent[] : [];
    const pb = selectedContents[0]?.pixelBox;

    return (<>
        {notes}
        <PostItEditor
            id={selectedPostItNote} 
            pixelBox={pb}
            onDraw={onDraw} 
            onDismiss={() => setSelectedPostItNote("")} 
        />
    </>);
};
