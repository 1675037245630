import React, { useRef, useState, useEffect, useMemo, useContext, RefObject } from 'react';
import { IonContent } from '@ionic/react';
import BrushCatcher from './BrushCatcher';
import './Canvas.css';
import SocketService, { useChunkSendQueue } from "../services/socket";
import RequestBoardIdModal from './RequestBoardIdModal';
import SlidingToolbar from './SlidingToolbar';
import QrCodeOverlay from './QrCodeOverlay';
import LayerSelectionOverlay from './LayerSelectionOverlay';
import ToolSelectorOverlay from './ToolSelectorOverlay';
import ColorPaletteOverlay from './ColorPaletteOverlay';
import { DrawingToolProps, Point, useDrawTool } from '../services/DrawTool';
import { usePaintbrush } from '../services/PaintBrush';
import { useLineBrush } from '../services/LineBrush';
import BackgroundGridToolOverlay from './BackgroundGridToolOverlay';
import drawBoxGrid from '../helpers/drawBoxGrid';
import drawDotGrid from '../helpers/drawDotGrid';
import drawLineGrid from '../helpers/drawLineGrid';
import LineWidthSelectorOverlay from './LineWidthSelectorOverlay';
import { MappedChunkData, chunkSize, useModifiedChunks } from '../helpers/chunkTools';
import { b2point } from '../helpers/byteTools';
import { useShadowPlot } from '../helpers/shadowPlot';
import { useContextPlot } from '../helpers/contextPlot';
import RequestUserInfoModal from './RequestUserInfoModal';
import { UserData } from '../helpers/userTools';
import UserViewingOverlay from './UserViewingOverlay';
import Carousel from './DraggableCarousel';
import { boardHeight, boardWidth, useScreenResize } from '../context/ScreenSizeContext';
import {usePostItNoteBrush, usePostItNoteService} from '../services/PostItNoteService';
import { PostItNoteOverlay } from './PostItNoteOverlay';

const gridStyles = [
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    ctx.clearRect(0, 0, width, height);
  },
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    drawBoxGrid(ctx, width, height, 10, offset);
  },
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    drawBoxGrid(ctx, width, height, 20, offset);
  },
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    drawDotGrid(ctx, width, height, 10, offset);
  },
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    drawDotGrid(ctx, width, height, 20, offset);
  },
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    drawLineGrid(ctx, width, height, 30, offset, 0);
  },
  (ctx: CanvasRenderingContext2D, width: number, height: number, offset: Point) => {
    drawLineGrid(ctx, width, height, 30, offset, 1);
  },
]

function getCertainCanvasChunk(ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, point: Point): ImageData {
  return ctx.getImageData(point.x * chunkSize, point.y * chunkSize, chunkSize, chunkSize);
}

function putCertainCanvasChunk(ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D, chunk: MappedChunkData) {
  const { x, y } = b2point(chunk.id);
  const imageData = new ImageData(new Uint8ClampedArray(chunk.data), chunkSize, chunkSize);
  ctx.putImageData(imageData, x * chunkSize, y * chunkSize);
}

function getBoundingRectangle(x1: number, y1: number, x2: number, y2: number, lineWidth: number) {
  const halfWidth = lineWidth / 2;
  return {
      left: Math.min(x1, x2) - halfWidth,
      right: Math.max(x1, x2) + halfWidth,
      top: Math.min(y1, y2) - halfWidth,
      bottom: Math.max(y1, y2) + halfWidth,
  };
}

const Canvas: React.FC<{ id?: string }> = ({id}) => {

  const offscreenCtxRefs = Array.from({ length: 5 }, () => useRef<OffscreenCanvasRenderingContext2D>(new OffscreenCanvas(boardWidth, boardHeight).getContext('2d', { alpha: true, willReadFrequently: true })));
  const onScreenCanvasRefs = Array.from({ length: 7 }, () => useRef<HTMLCanvasElement>(null));

  const [selectedTool, setSelectedTool] = useState<number>(0);
  const [lineWidth, setLineWidth] = useState<number>(3);
  const [selectedLayer, setSelectedLayer] = useState<number>(1);
  const [selectedGrid, setSelectedGrid] = useState<number>(0);
  const [snapToGrid, setSnapToGrid] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<string>('#000000');
  const [initialized, setInitialized] = useState(true);
  const [boardId, setBoardId] = useState<string>('');
  const [erase, setErase] = useState<boolean>(false);
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
  const [userData, setUserData] = useState<{ uuid: string, username: string, password: string | null }>({ uuid: '', username: '', password: '' });
  const [viewers, setViewers] = useState<{ [key: string]: { username: string, active: boolean, owner: boolean } }>({});
  
  const screenSize = useScreenResize();
  const { postItNotes, resetPostItNotes } = usePostItNoteService();

  const animationRef = useRef<number>(0);
  const animationLoopRef = useRef<() => void>(() => {return;});
  const paintBrush = usePaintbrush((strokeStart, strokeEnd) => {
      const realStrokeStart = { x: strokeStart.x + screenSize.offset.x, y: strokeStart.y + screenSize.offset.y };
      const realStrokeEnd = { x: strokeEnd.x + screenSize.offset.x, y: strokeEnd.y + screenSize.offset.y };
      contextPlot(selectedColor, lineWidth, [realStrokeStart, realStrokeEnd], erase);
      //sendPathToClients([strokeStart, strokeEnd]);
  });

  const postItNoteBrush = usePostItNoteBrush(() => setSelectedTool(0));

  const currentCtx = offscreenCtxRefs[selectedLayer].current;
  const shadowPlot = useMemo(() => useShadowPlot(
    onScreenCanvasRefs[6].current?.getContext('2d', { alpha: true, willReadFrequently: true }), 
    screenSize.width, 
    screenSize.height
  ), [onScreenCanvasRefs[6].current, screenSize]);

  const lineBrush = useLineBrush({ draw: (strokeStart, strokeEnd) => {
    shadowClear();
    const realStrokeStart = { x: strokeStart.x + screenSize.offset.x, y: strokeStart.y + screenSize.offset.y };
    const realStrokeEnd = { x: strokeEnd.x + screenSize.offset.x, y: strokeEnd.y + screenSize.offset.y };
    contextPlot(selectedColor, lineWidth, [realStrokeStart, realStrokeEnd], erase);
    //sendPathToClients([strokeStart, strokeEnd]);
  }, shadowDraw: (fromPoint, toPoint) => {
    shadowPlot([fromPoint, toPoint]);
  }});

  useEffect(() => {
    animationLoopRef.current = () => {
      for (let i = 0; i < offscreenCtxRefs.length; i++) {
        const ctx1 = offscreenCtxRefs[i].current;
        const ctx2 = onScreenCanvasRefs[i+1].current?.getContext('2d');
        if (!ctx1 || !ctx2) continue;
        ctx2.putImageData(ctx1.getImageData(screenSize.offset.x, screenSize.offset.y, screenSize.width, screenSize.height), 0, 0);
      }
    };
    
    gridPlot(selectedGrid);
  }, [screenSize.width, screenSize.height, screenSize.offset, screenSize.isMoving]); 

  useEffect(() => {
    if (!screenSize.isMoving) {
      console.log('canvas resizing');
      for (let i = 0; i < onScreenCanvasRefs.length; i++) {
        const canvas = onScreenCanvasRefs[i].current;
        if (!canvas) continue;
        canvas.width = screenSize.width;
        canvas.height = screenSize.height;
      }
    }
  }, [screenSize.width, screenSize.height, screenSize.zoomFactor])

  const toolUsed: DrawingToolProps = (() => {
    switch (selectedTool) {
      case 0:
        return paintBrush;
      case 1:
        return lineBrush;
      case 4: 
        return postItNoteBrush;
      default:
        return paintBrush;
    }
  })();

  const { addModifiedChunk, dumpModifiedChunks } = useModifiedChunks();

  useEffect(() => {
    SocketService.connect();
    const loop = () => {
      animationLoopRef.current();
      animationRef.current = requestAnimationFrame(loop);
    };
    animationRef.current = requestAnimationFrame(loop);
    // Register the drawing path listener
    SocketService.onUpdateChunk((chunks) => {
      chunks.forEach((chunk) => {
        //console.log('Received chunk:', chunk);
        const { layer } = b2point(chunk.id);
        const ctx = offscreenCtxRefs[layer!].current;
        if (!ctx) return;
        putCertainCanvasChunk(ctx, chunk);
        //chunkPlot(canvasRefs[10].current!.getContext('2d')!, { x, y });
      });
    });

    SocketService.onUpdateParameter((data) => {
      if (data.grid !== undefined) {
        gridPlot(data.grid);
      }
    });

    SocketService.onUserData((data) => {
      console.log('User data:', data);
      if (!data) return;
      setUserData({...data, password: data.password === 'unset' ? null : ''});
    });

    SocketService.onConnectionStateChange(({prevState, newState}) => {
      if (prevState === "disconnected" && newState === "connected" && boardId.length > 0) {
        SocketService.joinBoard(boardId);
      }
    });

    SocketService.onBoardViewerUpdate((data) => {
      setViewers(data);
    });

    SocketService.onBoardCreated(resetBoard);
    SocketService.onBoardJoined(resetBoard);
    
    // try get stored board id from localStorage
    const storedBoardId = localStorage.getItem('boardId');
    if (storedBoardId && storedBoardId.length > 0) {  
      setBoardId(storedBoardId!);
      SocketService.joinBoard(storedBoardId!);
    }

    // Cleanup on unmount
    return () => {
      cancelAnimationFrame(animationRef.current);
      SocketService.disconnect();
    };
    
  }, []);

  const resetBoard = (data: { boardId: string, configId: string, chunksId: string }) => {
    //console.log('Resetting board:', data);
    resetPostItNotes();
    for (let i = 1; i < onScreenCanvasRefs.length; i++) {
      clearCanvas(i);
    }
    localStorage.setItem("boardId", data.boardId);
    setBoardId(data.boardId);
  };

  function markAreaAsModified(layer: number, x1: number, y1: number, x2: number, y2: number) {
    // Calculate bounding rectangle for the thick line
    const bounds = getBoundingRectangle(x1, y1, x2, y2, lineWidth);
    // Determine chunk range that intersects with the bounding rectangle
    const startChunkX = Math.floor(bounds.left / chunkSize);
    const endChunkX = Math.floor(bounds.right / chunkSize);
    const startChunkY = Math.floor(bounds.top / chunkSize);
    const endChunkY = Math.floor(bounds.bottom / chunkSize);
    // Loop over the chunks in the bounding box range
    for (let chunkX = startChunkX; chunkX <= endChunkX; chunkX++) {
        for (let chunkY = startChunkY; chunkY <= endChunkY; chunkY++) {
            if (chunkX < 0 || chunkY < 0 || chunkX > 65535 || chunkY > 65535) continue;
            addModifiedChunk({ layer, x: chunkX, y: chunkY });
        }
    }
  }

  const clearCanvas = (layer: number) => {
    const ctx1 = offscreenCtxRefs[layer]?.current;
    const ctx2 = onScreenCanvasRefs[layer].current?.getContext('2d');
    if (!ctx1 && !ctx2) return;
    ctx1?.clearRect(0, 0, boardWidth, boardHeight);
    ctx2?.clearRect(0, 0, screenSize.width, screenSize.height);
  }

  const shadowClear = () => {
    const ctx = onScreenCanvasRefs[6].current?.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, screenSize.width, screenSize.height);
  }

  const gridPlot = (grid: number) => {
    const canvas = onScreenCanvasRefs[0].current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    gridStyles[grid](ctx, canvas.width, canvas.height, screenSize.offset);
    setSelectedGrid(grid);
  }

  const sendChunks = async (chunks: MappedChunkData[]) => {
    //console.log('chunksid', SocketService.getChunkId());
    await SocketService.sendChunks(SocketService.getChunkId(), chunks)
  };

  const { queueChunks } = useChunkSendQueue();

  const contextPlot = useContextPlot(currentCtx, (affectedAreas) => {
    if (!currentCtx) return;
    affectedAreas.forEach((area) => {
      markAreaAsModified(selectedLayer, area.start.x, area.start.y, area.end.x, area.end.y);
    });
    dumpModifiedChunks((_chunks: MappedChunkData[]) => queueChunks((chunks) => sendChunks(chunks), _chunks), modChunk => ({ id: modChunk, data: new Uint8Array(getCertainCanvasChunk(currentCtx, b2point(modChunk)).data.buffer) }));
  });

  const canvasRefStack = useMemo(() => onScreenCanvasRefs.map((canvasRef, index) => (
    <canvas
      key={index}
      className="onscreenCanvas"
      ref={canvasRef} // End drawing if the mouse leaves the canvas
    />
  )), [screenSize]);

  return (
      <IonContent fullscreen scrollY={false}>
        <BrushCatcher
          toolUsed={toolUsed}
          snapToGrid={snapToGrid}
          gridSpacing={10}
          lineWidth={lineWidth}
          erase={erase}
        >
          {canvasRefStack}
          <PostItNoteOverlay postItNotes={postItNotes} />
        </BrushCatcher>
        <RequestUserInfoModal
          isOpen={showUserInfo}
          onClose={() => setShowUserInfo(false)}
          onSubmit={({ uuid, username, password, newPassword }: UserData) => {
            SocketService.updateUser({ uuid, username, password, newPassword });
          }}
          currentUserInfo={userData}
        />
        <RequestBoardIdModal 
          currentBoardId={boardId}
          isOpen={!initialized} 
          onClose={() => setInitialized(true)} 
          onSubmit={(_boardId: string) => {
            console.debug('Board ID submitted:', _boardId);
            if (!_boardId || boardId === _boardId) return;
            setInitialized(true);
            setBoardId(_boardId);
            localStorage.setItem("boardId", _boardId);
            SocketService.joinBoard(_boardId);
          }} 
        />
        <Carousel
          items={[
            (<ToolSelectorOverlay
              key={1}
              onToolSelect={(tool) => setSelectedTool(tool)}
              selectedTool={selectedTool}
              setEraser={setErase}
              eraser={erase}
            />),
            (<BackgroundGridToolOverlay
              key={2}
              onGridSelect={(grid) => {
                SocketService.updateParameter(boardId, { grid });
                if (grid === 0) {
                  setSnapToGrid(false);
                }
                gridPlot(grid);
              }}
              onSnapToGrid={(snap) => setSnapToGrid(snap)}
              snapToGrid={snapToGrid}
              selectedGrid={selectedGrid}
            />),
            (<ColorPaletteOverlay
                key={3}
                onColorSelect={(color) => setSelectedColor(color)}
                selectedColor={selectedColor}
            />),
            (<LineWidthSelectorOverlay
              key={4}
              onLineWidthSelect={(width) => setLineWidth(width)}
              selectedWidth={lineWidth}
            />),
            (<QrCodeOverlay boardId={boardId} key={5} />),
            
            (<LayerSelectionOverlay
              key={7}
              boardId={boardId}
              selectedLayer={selectedLayer}
              onLayerSelect={setSelectedLayer}
            />),(<UserViewingOverlay key={7} viewers={viewers} />)
          ]}
        />
        
        <SlidingToolbar
          onUserInfo={() => setShowUserInfo(true)}
          onNewBoard={() => SocketService.createBoard('New board')}
          onOpenModal={() => setInitialized(false)}
        />
        
        
      </IonContent>
  );
};

export default Canvas;
