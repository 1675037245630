import { Point } from "../services/DrawTool";

export default function drawBoxGrid(ctx: CanvasRenderingContext2D, width: number, height: number, boxSize: number, offset: Point) {
    const offsetX = offset.x % boxSize;
    const offsetY = offset.y % boxSize;
    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.lineWidth = 0.3;
    ctx.strokeStyle = "#aaaaaa";
    for (let x = boxSize-offsetX; x < width; x += boxSize) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
    }
    for (let y = boxSize-offsetY; y < height; y += boxSize) {
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
    }
    ctx.stroke();
}