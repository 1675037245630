import React, { useEffect } from 'react';
import './Home.css';
import Canvas from '../components/Canvas';
import { IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';

const Home: React.FC<RouteComponentProps> = (props) => {

  const id = (props.match.params as unknown as { id: string }).id

  useEffect(() => {
    if (id) {
      console.log(`Board ID: ${id}`);
      localStorage.setItem('boardId', id);
      props.history.replace('/');
    }
  }, []);

  return id ? null : (
    <IonPage id="main-content">
        <Canvas id={id} />
    </IonPage>
  );
};

export default Home;
