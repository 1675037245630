import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as config from "../config.json";

export const { boardWidth, boardHeight } = config;

// Define the context shape
export interface ScreenSize {
  width: number;
  height: number;
}

export interface ScreenSizeProps extends ScreenSize {
  offset: ScreenOffset;
  isMoving: boolean;
  zoomFactor: number;
  setZoomFactor: (factor: number) => void;
  visibleArea: ScreenSize;
}

export interface ScreenOffset {
  x: number;
  y: number;
}

// Create the context
const ScreenResizeContext = createContext<ScreenSizeProps | undefined>(undefined);

let wheelTimeout: NodeJS.Timeout;
// Context provider component
export const ScreenResizeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  
  const [screenSize, setScreenSize] = useState<ScreenSize>({
      width: window.innerWidth,
      height: window.innerHeight,
  });

  const prevTouchDistance = useRef<number>(0);

  const maxZoomFactor = useMemo(() => {
      const mzf = Math.max(boardWidth / screenSize.width, boardHeight / screenSize.height);
      console.log('Max zoom factor', mzf);
      return mzf;
  }, [screenSize]);

  const [isMoving, setIsMoving] = useState(false);

  const prevClientXY = useRef({ x: 0, y: 0 });

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [ visibleArea, _setVisibleArea ] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [ zoomFactor, _setZoomFactor ] = useState<number>(1);

  const setZoomFactor = (factor: number) => {
      const clampedFactor = Math.min(Math.max(zoomFactor + factor, 0.5), maxZoomFactor);
      const _visibleArea = { width: Math.floor(screenSize.width * clampedFactor), height: Math.floor(screenSize.height * clampedFactor) };
      const sizeChange = { width: _visibleArea.width - visibleArea.width, height: _visibleArea.height - visibleArea.height };
      const newOffset = { x: sizeChange.width / 2, y: sizeChange.height / 2 };
      _setZoomFactor(clampedFactor);
      _setVisibleArea(_visibleArea);
      setOffset((prev) => {
          const { x, y } = prev;
          const maxX = boardWidth - screenSize.width;
          const maxY = boardHeight - screenSize.height;
          const clampedX = Math.min(Math.max(newOffset.x, 0), maxX);
          const clampedY = Math.min(Math.max(newOffset.y, 0), maxY);
          return { x: clampedX, y: clampedY };
      });
  }

  const traverseDragRef = useRef<HTMLDivElement>(null);

  const eventCache = useRef<PointerEvent[]>([]);

  const handlePointerEvent = (event: PointerEvent) => {
      console.log('Pointer event', event);
      event.preventDefault();
      if (eventCache.current.length === 0 || eventCache.current.findIndex(e => e.pointerId === event.pointerId) === -1) {
          prevTouchDistance.current = 0;
          eventCache.current.push(event);
      }
      if (eventCache.current.length === 2) {
          console.log('Two finger pinchy');
          const xDiff = eventCache.current[0].clientX - eventCache.current[1].clientX;
          const yDiff = eventCache.current[0].clientY - eventCache.current[1].clientY;
          const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
          if (Math.abs(prevTouchDistance.current) > 0) {
              const delta = distance - prevTouchDistance.current;
              setZoomFactor(zoomFactor + delta / 100);
          }
          prevTouchDistance.current = distance;
      }
  }


  const handleTouch = (event: TouchEvent) => {
      if (event.touches.length === 2) {
          event.preventDefault();
          event.stopPropagation();
          const touch1 = event.touches[0];
          const touch2 = event.touches[1];
          const distance = Math.sqrt(
              Math.pow(touch1.clientX - touch2.clientX, 2) +
              Math.pow(touch1.clientY - touch2.clientY, 2)
          );
          if (distance < 100) {
              setIsMoving(false);
          } else {
              if (!isMoving) {
                setIsMoving(true);
                prevClientXY.current = { x: (touch1.clientX + touch2.clientX) / 2, y: (touch1.clientY + touch2.clientY) / 2 };
                return;
              }
              setOffset((prev) => {
                  const { x, y } = prev;
                  const maxX = boardWidth - screenSize.width;
                  const maxY = boardHeight - screenSize.height;
                  const currentTouchy = { x: (touch1.clientX + touch2.clientX) / 2, y: (touch1.clientY + touch2.clientY) / 2 };
                  const clampedX = Math.min(Math.max(x + (currentTouchy.x - prevClientXY.current.x), 0), maxX);
                  const clampedY = Math.min(Math.max(y + (currentTouchy.y - prevClientXY.current.y), 0), maxY);
                  prevClientXY.current = currentTouchy;
                  return { x: clampedX, y: clampedY };
              });
          }
      } else {
        setIsMoving(false);
      }
    }
  
  const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      wheelTimeout && clearTimeout(wheelTimeout);
      wheelTimeout = setTimeout(() => setIsMoving(false), 100);
      const isTwoFingerScroll = event.ctrlKey || event.deltaMode === 0; // Detect trackpad-like gestures
      if (isTwoFingerScroll) {
          if (!isMoving) setIsMoving(true);
          setOffset((prev) => {
              const { x, y } = prev;
              const maxX = boardWidth - screenSize.width;
              const maxY = boardHeight - screenSize.height;
              const clampedX = Math.min(Math.max(x + event.deltaX, 0), maxX);
              const clampedY = Math.min(Math.max(y + event.deltaY, 0), maxY);
              return { x: clampedX , y: clampedY };
          });
      } 
  };

  useEffect(() => {
      const canvas = traverseDragRef.current;

      if (canvas) {
        canvas.addEventListener("wheel", handleWheel, { passive: false });
        canvas.addEventListener("touchmove", handleTouch, { passive: false });
        //canvas.addEventListener("pointermove", handlePointerEvent, { passive: false });
        return () => {
            canvas.removeEventListener("wheel", handleWheel);
            canvas.removeEventListener("touchmove", handleTouch);
            //canvas.removeEventListener("pointermove", handlePointerEvent);
        };
      }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
            ref={traverseDragRef}
            style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
            }}
    >
      <ScreenResizeContext.Provider value={{...screenSize, offset, isMoving, zoomFactor, setZoomFactor, visibleArea }}>
        {children}
      </ScreenResizeContext.Provider>
    </div>
  );
};

// Custom hook to use the context
export const useScreenResize = (): ScreenSizeProps => {
  const context = useContext(ScreenResizeContext);
  if (!context) {
    throw new Error("useScreenResize must be used within a ScreenResizeProvider");
  }
  return context;
};
